<template>
	<div v-show="show" ref="root">
		<div class="container dialog position-absolute top-50 start-50 translate-middle p-0 rounded">
			<div class="header d-block" :class="this.typeClass"></div>
			<div class="body p-3 bg-white" v-html="this.message">
			</div>
			<div class="modal-footer bg-white p-1">
				<button type="button" class="btn btn-danger" @click="this.cancel()">{{ this.cancelString }}</button>
				<button type="button" class="btn btn-success" @click="this.accept()">{{ this.acceptString }}</button>
			</div>
		</div>
		<div class="modal-backdrop fade show"></div>
	</div>
</template>

<script>
import { render } from 'vue';
import { removeElement } from './helper.js';

export default {
	name: "ConfirmDialog",
	props: { message: String, type: String, cancelString: String, acceptString: String, callback: Function },
	data: () => {
		return {
			typeClass: null,
			show: false,
		}
	},
	mounted() {
		switch (this.type) {
			case 'danger':
				this.typeClass = 'bg-danger';
				break;
			case 'success':
				this.typeClass = 'bg-success';
				break;
			case 'warning':
				this.typeClass = 'bg-warning';
				break;
			case 'primary':
				this.typeClass = 'bg-primary';
				break;
			case 'info':
				this.typeClass = 'bg-info';
				break;
		}

		this.show = true;
	},
	methods: {
		accept() {
			this.callback();
			this.show = false;
			this.afterLeave();
		},

		cancel() {
			this.show = false;
			this.afterLeave();
		},

		afterLeave() {
			const wrapper = this.$refs.root;
			render(null, wrapper);
			removeElement(wrapper);
		}
	},
}
</script>

<style scoped>
@media screen and (max-width: 450px) {
	.dialog {
		width: 90% !important;
	}

}
.dialog {
	overflow: auto;
	width: 400px;
	z-index: 9999;
}

.modal-backdrop {
	z-index: 9900;
}

.header {
	overflow: hidden;
	width: 100%;
	height: 5px;
}
</style>
